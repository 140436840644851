import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { artShopApi } from "../../../utils/api/api";
import Swiper, { Navigation, Pagination, Autoplay } from "swiper";
import { handleCheckImgSize } from "../../../utils/utils";

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL_GALLERY;
const ART_SHOP = process.env.REACT_APP_PATH_ART_SHOP;
const EXHIBITION = process.env.REACT_APP_PATH_EXHIBITION;
const IMAGE = process.env.REACT_APP_PATH_IMAGE;
const THUMBNAIL = process.env.REACT_APP_PATH_THUMBNAIL;

const ArtShopDetail = () => {
  Swiper.use([Navigation, Pagination, Autoplay]);
  const { board_sc, board_se } = useParams();
  const [shopDetail, setShopDetail] = useState(null);
  const [randomItems, setRandomItems] = useState([]);
  const [images, setImages] = useState([]);

  const SECTION = board_sc === "1" ? EXHIBITION : ART_SHOP;

  useEffect(() => {
    if (board_sc && board_se) {
      getExhibitDetail(board_sc, board_se);
    }
  }, [board_sc, board_se]);

  /** GET 아트샵 상품 구분 (Original, Poster, Goods) */
  const getExhibitDetail = (boardSc, boardSe) => {
    if (boardSc === "1") { // Original
      artShopApi.getExhibitDetail(boardSe)
        .then((response) => {
          const { status, data } = response;

          if (status === 200) {
            setShopDetail(data.shopDetail);
            setRandomItems(data.randomItems);
            setImages(data.images);
          }
        })
        .catch((error) => {
          alert(error.message)
        })
    } else if (boardSc === "2" || boardSc === "3") { // Poster, Goods
      artShopApi.getArtShopDetail(boardSe)
        .then((response) => {
          const { status, data } = response;

          if (status === 200) {
            setShopDetail(data.shopDetail);
            setRandomItems(data.randomItems);
            setImages(data.images);
          }
        })
        .catch((error) => {
          alert(error.message)
        })
    }
  }

  /** 아트샵 작품 및 굿즈 swiper */
  useEffect(() => {
    if (shopDetail !== null) {
      new Swiper('.action-wrap', {
        slidesPerView: 1,
        loop: false,
        allowTouchMove: false,
        navigation: {
          nextEl: '.art-next',
          prevEl: '.art-prev',
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      });
    }
  }, [shopDetail, images]);

  /** 현재 작가의 다른 작품 및 굿즈 클릭 이벤트 */
  const handleAnotherItem = (value) => {
    setShopDetail(null);
    setRandomItems([]);
    getExhibitDetail(board_sc, value);
  }

  return (
    <section className="artshop-wrapper detail">
      <h2 className="hidden-obj">아트샵</h2>
      <article>
        {/* 작품 정보 및 구매 */}
        <div className="art-view">
          {/* 작품 이미지 슬라이드 */}
          {
            (shopDetail !== null) &&
            <div className="action-slide">
              <div className="action-wrap artinfo-swiper swiper-container">
                <ul className="action-img swiper-wrapper">
                  <li className="swiper-slide">
                    <img
                      src={IMAGE_URL + SECTION + THUMBNAIL + shopDetail.thumbnail_lk}
                      alt={shopDetail.thumbnail_lk}
                    />
                  </li>
                  {
                    images.length > 0 &&
                    images.map((image, index) => (
                      <li key={index} className="swiper-slide">
                        <img
                          src={IMAGE_URL + SECTION + IMAGE + image.image_lk}
                          alt={image.image_lk}
                        />
                      </li>
                    ))
                  }
                </ul>
                {
                  images.length > 0 &&
                  <>
                    <div className="swiper-button-prev art-prev"></div>
                    <div className="swiper-button-next art-next"></div>
                    <div className="swiper-pagination swiper-pagination-bullets swiper-pagination-horizontal"></div>
                  </>
                }
              </div>
            </div>
          }
          {/* 작품 정보 */}
          <div className="art-info">
            {
              (shopDetail !== null) &&
              <>
                <div className="kind">{shopDetail.board_sc_tt}</div>
                <div className="art-name">{shopDetail.board_tt}</div>
                <div className="name">
                  {shopDetail.artist_se !== 1 && shopDetail.artist_nm}
                </div>
                <div className="price">
                  {
                    shopDetail.price_mo === 0
                      ? "가격문의"
                      : `${shopDetail.price_mo.toLocaleString()} won`
                  }
                </div>
                {
                  board_sc !== "3" && (
                    <div className="info-box">
                      <ul className="list">
                        <li
                          className="on"
                        >
                          <Link to="#">작품정보</Link>
                        </li>
                      </ul>
                      <div className="txt-box">
                        {/* 작품정보 */}
                        <div className="info">
                          {
                            shopDetail.size_if &&
                            <dl>
                              <dt>크기</dt>
                              <dd>{shopDetail.size_if}</dd>
                            </dl>
                          }
                          {
                            shopDetail.comment_if &&
                            <dl>
                              <dt>제작방식</dt>
                              <dd>{shopDetail.comment_if}</dd>
                            </dl>
                          }
                          {
                            shopDetail.work_yr &&
                            <dl>
                              <dt>제작연도</dt>
                              <dd>{shopDetail.work_yr}</dd>
                            </dl>
                          }
                        </div>
                      </div>
                    </div>
                  )
                }
                <div className="amount-area">
                  <div className="txt">상품금액</div>
                  <div className="amount">
                    {
                      shopDetail.isPriceDisplayed
                        ?
                        <div id="total">
                          {(shopDetail.price_mo.toLocaleString()) + "원"}
                        </div>
                        :
                        <div id="total">가격문의</div>
                    }
                  </div>
                </div>
                <div className="btn-area">
                  {
                    shopDetail.purchase_lk ? (
                      shopDetail.stock_yn === "n" ? (
                        <Link to="#" className="btn-purchase disable">구매완료</Link>
                      ) : (
                        <a
                          className="btn-purchase"
                          href={shopDetail.purchase_lk}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          상품 구매페이지로 이동하기
                        </a>
                      )
                    ) : (
                      <Link to="#" className="btn-purchase disable">준비중입니다</Link>
                    ) 
                  }
                  {
                    
                  }
                </div>
              </>
            }
          </div>
        </div>
        {/* 이 작가의 다른 작품 */}
        <div className="unlike">
          <h3>이 작가의 다른 작품</h3>
          <div className="art-area">
            <ul className="art-list">
              {
                randomItems.length > 0 &&
                randomItems.map((row) => (
                  <li
                    key={row.board_se}
                    onClick={() => handleAnotherItem(row.board_se)}
                  >
                    <div className="frame">
                      <span>
                        <img
                          onLoad={handleCheckImgSize}
                          src={IMAGE_URL + SECTION + THUMBNAIL + row.thumbnail_lk}
                          alt={row.thumbnail_lk}
                        />
                      </span>
                    </div>
                    <dl>
                      <dt>{row.board_tt}</dt>
                      <dd>
                        {
                          row.isPriceDisplayed
                            ?
                            <>
                              {(row.price_mo.toLocaleString()) + "원"}
                            </>
                            :
                            "가격문의"
                        }
                      </dd>
                    </dl>
                  </li>
                ))
              }
            </ul>
          </div>
        </div>
      </article>
    </section>
  )
}

export default ArtShopDetail;
