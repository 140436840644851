import Home from '../components/pages/Home/index';
import Exhibition from "../components/pages/Exhibition/index";
import ExhibitionDetail from "../components/pages/Exhibition/exhibitionDetail";
import ExhibitionCategory from "../components/pages/Exhibition/exhibitionCategory";
import ExhibitionCategoryList from "../components/pages/Exhibition/exhibitionCategoryList";
import iGallery from "../components/pages/iGallery/index";
import ArtShop from '../components/pages/ArtShop/index';
import ArtShopDetail from '../components/pages/ArtShop/artShopDetail';
import Notice from '../components/pages/Notice/index';
import NoticeDetail from '../components/pages/Notice/noticeDetail';
import About from '../components/pages/About/index';
import Directions from '../components/pages/About/directions';
import Search from '../components/pages/Search/index';
import Error from '../components/common/error';

const HOME = "/";
const EXHIBITION = "/exhibition";
const EXHIBITION_DETAIL = "/exhibitionDetail"
const EXHIBITION_CATEGORY = "/exhibitionCategory"
const EXHIBITION_CATEGORY_LIST = "/exhibitionCategoryList"
const IGALLERY = "/igallery";
const ARTSHOP = "/artshop";
const ARTSHOP_DETAIL = "/artShopDetail"
const NOTICE = "/notice";
const NOTICE_DETAIL = "/noticedetail";
const SEARCH = "/search";
const DIRECTIONS = "/directions";
const ABOUT = "/about";

export const routes = {
    home: HOME,
    igallery: IGALLERY,
    exhibition: EXHIBITION,
    exhibitionDetail: EXHIBITION_DETAIL,
    exhibitionCategory: EXHIBITION_CATEGORY,
    exhibitionCategoryList: EXHIBITION_CATEGORY_LIST,
    artshop: ARTSHOP,
    artshopDetail: ARTSHOP_DETAIL,
    notice: NOTICE,
    noticeDetail: NOTICE_DETAIL,
    search: SEARCH,
    directions: DIRECTIONS,
    about: ABOUT,
};

export const components = {
    home: Home,
    exhibition: Exhibition,
    exhibitionDetail: ExhibitionDetail,
    exhibitionCategory: ExhibitionCategory,
    exhibitionCategoryList: ExhibitionCategoryList,
    igallery: iGallery,
    artshop: ArtShop,
    artshopDetail: ArtShopDetail,
    notice: Notice,
    search: Search,
    directions: Directions,
    about: About,
    error: Error,
    noticeDetail: NoticeDetail
}