import axios from "axios";
import { routes } from "../../routes/routes";

const api = axios.create({
    baseURL: "/"
});

//각 URL에 맞게 컨트롤러와 통신하여 json 오브젝트를 받아오는 것들

export const homeApi = {
    getBannerSlide: (section_cd) => api.get(`Home/Slide?section_cd=${section_cd}`),
    topBanner: (banner_sc) => api.get(`Home/topbanner/${banner_sc}`),
}

export const noticeApi = {
    allPost: (current_page) => api.get(`${routes.notice}/${current_page}`),
    detailPost: (board_se) => api.get(`${routes.noticeDetail}/Content/${board_se}`),
    detailImage: (board_se) => api.get(`${routes.noticeDetail}/Content/Image/${board_se}`),
}

export const artShopApi = {
    //아트샵 (Original)
    getExhibitList: (current_page, page_size, filter) => api.get(`api/ArtShop/exhibition?current_page=${current_page}&page_size=${page_size}&filter=${filter}`),
    getExhibitDetail: (board_se) => api.get(`api/ArtShop/exhibition/${board_se}`),

    //아트샵 (Poster, Goods)
    getArtShopList: (current_page, page_size, board_sc_cd, filter) => api.get(`api/ArtShop/stuff?current_page=${current_page}&page_size=${page_size}&board_sc_cd=${board_sc_cd}&filter=${filter}`),
    getArtShopDetail: (board_se) => api.get(`api/ArtShop/stuff/${board_se}`),
}

export const exhibitionApi = {
    allSlide: () => api.get(`${routes.exhibition}/Slide`),
    allYear: () => api.get(`${routes.exhibition}/Year`),
    detailPost: (exhibits_se) => api.get(`${routes.exhibitionDetail}/Content/${exhibits_se}`),
    categoryPost: (exhibits_se) => api.get(`${routes.exhibitionDetail}/Category/${exhibits_se}`),
    ExhibitsMainList: (current_page, page_size, exhibits_sc, year) => api.get(`${routes.exhibition}/Main?current_page=${current_page}&page_size=${page_size}&exhibits_sc=${exhibits_sc}&year=${year}`),
    ExhibitsCategoryList: (current_page, page_size, pre_exhibits_se) => api.get(`${routes.exhibition}/CategoryList?current_page=${current_page}&page_size=${page_size}&pre_exhibits_se=${pre_exhibits_se}`)
}

export const iGalleryApi = {
    detailInfo: () => api.get(`${routes.igallery}/info`),
    detailIntro: () => api.get(`${routes.igallery}/intro`),
    detailArtist: () => api.get(`${routes.igallery}/artist`),
    allWorks: (artist_se) => api.get(`${routes.igallery}/works/${artist_se}`),
    allSlide: () => api.get(`${routes.igallery}/info/slide`),
}

export const searchApi = {
    allWorks: (search_va) => api.get(`${routes.search}/Works/${search_va}`),
    allItems: (search_va) => api.get(`${routes.search}/Shop/${search_va}`),
}