import React, { useState, useEffect, useRef } from 'react';
import { routes } from "../../../routes/routes";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { exhibitionApi } from "../../../utils/api/api";

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL_GALLERY;
const EXHIBITION = process.env.REACT_APP_PATH_EXHIBITION;
const IMAGE = process.env.REACT_APP_PATH_IMAGE;

export const ExhibitionCategory = () => {
    const { exhibits_se } = useParams();
    const [detailPost, setDetailPost] = useState(null);
    const [detailSubPost, setDetailSubPost] = useState(null);
    const history = useHistory();

    const worksRef = useRef(null);

    /** GET 전시 작품 아트웍 상세 정보 */
    useEffect(() => {
        if (detailPost === null) {
            exhibitionApi.categoryPost(exhibits_se)
                .then((response) => {
                    const { status, data } = response;

                    if (status === 200) {
                        setDetailPost(data.data[0]);
                        setDetailSubPost(data.sub_data);
                    }
                })
                .catch((error) => {
                    setDetailPost(null);
                    setDetailSubPost(null);
                    console.log(error);
                })
        }
    }, [exhibits_se]);

    const scrollToWorks = () => {
        if (worksRef.current) {
            const offset = 100; // 상단에서 약간의 여백 추가
            const elementPosition = worksRef.current.getBoundingClientRect().top + window.scrollY;
            window.scrollTo({
                top: elementPosition - offset, // 정확한 위치로 스크롤
                behavior: "smooth",
            });
        }
    };

    return (
        <section className="art-wrapper detail">
            <h2 className="hidden-obj">전시작품</h2>
            <article>
                <div className="art-info">
                    {
                        detailPost &&
                        <>
                            <div className="category-div">
                                <div className="left-div">
                                    <div className="img-div">
                                        <img
                                            src={IMAGE_URL + EXHIBITION + IMAGE + detailPost.thumbnail_lk}
                                            alt={detailPost.thumbnail_lk}
                                        />
                                    </div>
                                </div>
                                <div className="right-div">
                                    <div className="title">{detailPost.exhibits_tt}</div>
                                    <div className="nm">{detailPost.visual_name}</div>
                                    <div className="perid">{detailPost.period}</div>
                                </div>
                            </div>

                            <div className="tab-div">
                                <div className="tab-intro">Introduciton</div>
                                <div className="tab-works" onClick={scrollToWorks}>Works</div>
                            </div>

                            <div className="tab-content-introduction">
                                <div className="title">Introduction</div>
                                <div className="txt-desc" dangerouslySetInnerHTML={{ __html: detailPost.exhibits_ct }}></div>
                            </div>
                            <div ref={worksRef} className="tab-content-works">
                                <div className="between">
                                    <div className="title">Works</div>
                                    <div className="allview" onClick={() => history.push(`${routes.exhibitionCategoryList}/${detailPost.exhibits_se}`)}>전체보기 &gt;</div>
                                </div>
                                <div className="work-item">
                                    {detailSubPost && detailSubPost.map((item, index) => (
                                        <div className="work-img" onClick={() => history.push(`${routes.exhibitionDetail}/${item.exhibits_se}`)}>
                                            <img
                                                src={IMAGE_URL + EXHIBITION + IMAGE + item.thumbnail_lk}
                                                alt={item.thumbnail_lk}
                                            />
                                            <div className="work-desc-div">
                                                <div className="work-title">{item.exhibits_tt}</div>
                                                <div className="work-artist">{item.artist_nm}</div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </>
                    }
                </div>
            </article>
        </section>
    );

}

export default ExhibitionCategory;