import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { routes } from "../../../routes/routes";
import { useHistory } from "react-router-dom";
import { exhibitionApi } from "../../../utils/api/api";
import { PaginationEx } from "../../common/pagination";
import { handleCheckImgSize } from "../../../utils/utils";

const PAGE_SIZE = 9;
const IMAGE_URL = process.env.REACT_APP_IMAGE_URL_GALLERY;
const EXHIBITION = process.env.REACT_APP_PATH_EXHIBITION;
const THUMBNAIL = process.env.REACT_APP_PATH_THUMBNAIL;

export const ExhibitionCategoryList = () => {
    const { pre_exhibits_se } = useParams();
    const [isLoading, setLoading] = useState(false);
    const [artWorkTt, setArtWorkTt] = useState({});
    const [artWorkList, setArtWorkList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalDataCnt, setTotalDataCnt] = useState(1);
    const history = useHistory();

    useEffect(() => {
        getExhibitsMainList(currentPage);
    }, [pre_exhibits_se]);

    const handlePageChange = (currentPage) => {
        setCurrentPage(currentPage);
        getExhibitsMainList(currentPage);
    }

    const getExhibitsMainList = (currentPage) => {
        setLoading(true);

        exhibitionApi.ExhibitsCategoryList(currentPage, PAGE_SIZE, pre_exhibits_se)
            .then((response) => {
                const { status, data } = response;
                if (status === 200) {
                    setArtWorkList(data.data);
                    setArtWorkTt(data.mainData);
                    setTotalDataCnt(Number(data.count));
                }
            })
            .catch(() => {
                setArtWorkList([]);
                setTotalDataCnt(0);
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <>
            <div className="category-list-wrapper">
                {isLoading && <></>}
                {
                    !isLoading && artWorkList.length === 0
                        ? <p className="no-data">작품이 없습니다.</p>
                        : (
                            <div className="category-list-div">
                                <div className="title-div">
                                    <div className="title">{artWorkTt.exhibits_tt}</div>
                                    <div className="visual-name">{artWorkTt.visual_name}</div>
                                </div>

                                <div className="category-tot">
                                    Exhibition ({totalDataCnt})
                                </div>
                                
                                <ul className="art-list gap16">
                                    {
                                        artWorkList.map((row) => (
                                            //<li key={row.exhibits_se} onClick={() => history.push(`${routes.exhibitionDetail}/${row.exhibits_se}`)}>
                                            <div className={`frame2`}>
                                                <div className={`frame2-img-div`}>
                                                    <img
                                                        onLoad={handleCheckImgSize}
                                                        src={IMAGE_URL + EXHIBITION + THUMBNAIL + row.thumbnail_lk}
                                                        alt=""
                                                        onClick={() => history.push(`${routes.exhibitionDetail}/${row.exhibits_se}`)}
                                                    />
                                                </div>
                                                <div className="frame2_box">
                                                    <div className="title" onClick={() => history.push(`${routes.exhibitionDetail}/${row.exhibits_se}`)}>{row.exhibits_tt}</div>
                                                    <div className="name" onClick={() => history.push(`${routes.exhibitionDetail}/${row.exhibits_se}`)}>{row.artist_nm}</div>
                                                </div>
                                            </div>

                                            //</li>
                                        ))
                                    }
                                </ul>
                            </div>
                        )
                }
                {

                    artWorkList.length > 0 &&
                    <PaginationEx
                        dataCount={totalDataCnt}
                        activePage={currentPage}
                        itemsCountPerPage={PAGE_SIZE}
                        pageRangeDisplayed={10}
                        onChange={handlePageChange}
                        hideFirstLastPages={true}
                    />

                }
            </div>
        </>
    );
}

export default ExhibitionCategoryList;